export const SITE_NAME = "DeCenter exchange";
export const SITE_URL = "https://decenter-exchange/";

export const TELEGRAM_URL = "https://t.me/DeCenter_btc";
export const SOCIAL_TELEGRAM_URL = "https://t.me/DeCenter_btc";
export const SOCIAL_YOU_TUBE_URL = "https://www.youtube.com/@";
export const SOCIAL_INSTAGRAM_URL = "https://instagram.com/";
export const SITE_EMAIL = "info@decenter-exchange.ru";

export const EXCHNAGER_ROUTE = "#exchanger";
export const REVIEWS_ROUTE = "#reviews";
export const OFFICES_ROUTE = "#offices";
export const SUPPORT_ROUTE = "#footer";
export const RULES_ROUTE = "/rules";
export const AML_ROUTE = "/aml";
export const AGREEMENTS_ROUTE = "/agreement";
